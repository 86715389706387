import React from 'react';
import type { GetStaticProps } from 'next';
import Head from 'next/head';
import { CollectionPage, Custom404, ProductPage } from '@ui/components/features';
import { getCollectionStaticPropsHelpers } from '@ui/nextServer/index';
import env from '@ui/env';
import { useRouter } from 'next/router';

type CollectionItem = Awaited<ReturnType<typeof getCollectionStaticPropsHelpers>>['props'];

const CollectionSlugPage = (props: CollectionItem) => {
  const router = useRouter();

  return (
    <div className="min-h-screen">
      {props.pageType === '404' && (
        <>
          <Head>
            <title>404 | Hello Molly</title>
          </Head>
          <Custom404 />
        </>
      )}
      {props.pageType !== '404' && (
        <>
          {props.pageType === 'product' && (
            <>
              <Head>
                {props.SEO.title && <title>{props.SEO.title}</title>}
                {
                  <link
                    rel="canonical"
                    href={`${env.NEXT_PUBLIC_BASE_URL}products/${(router.query.collections as Array<string>)
                      .slice(-1)
                      .toString()}`}
                  />
                }
              </Head>
              {props.product && <ProductPage product={props.product} />}
            </>
          )}
          {(props.pageType === 'collection' || props.pageType === 'category') && (
            <>
              <CollectionPage
                {...(props as Omit<Awaited<ReturnType<typeof getCollectionStaticPropsHelpers>>['props'], 'product'>)}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

const ERROR_NOT_FOUND_PROPS = {
  props: {
    pageType: '404',
    SEO: {
      title: '404 | Hello Molly',
    },
  },
};

export const getStaticProps: GetStaticProps = async (context) => {
  try {
    const result = await getCollectionStaticPropsHelpers(context);
    return result.props.pageType !== '404' ? result : ERROR_NOT_FOUND_PROPS;
  } catch (err) {
    if (err instanceof Error && err.message === 'Product not found') {
      return ERROR_NOT_FOUND_PROPS;
    } else {
      throw new Error(JSON.stringify(err));
    }
  }
};

export default CollectionSlugPage;
